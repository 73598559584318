<template>
  <transition>
    <!-- <div v-if="isLoading" class="loading">
      <div class="loader loader--style5" title="4">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="24px"
          height="30px"
          viewBox="0 0 24 30"
          style="enable-background:new 0 0 50 50;"
          xml:space="preserve"
        >
          <rect x="0" y="0" width="4" height="10" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="10" y="0" width="4" height="10" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0.2s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="20" y="0" width="4" height="10" fill="#333">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0.4s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      </div>
    </div> -->
  </transition>
</template>

<script>
export default {
  name: "PageLoader",
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }
};
</script>

<style scoped lang="sass">
.loading
	position: fixed
	top: 0
	left: 0
	height: 100vh
	width: 100vw
	z-index: 999999999999999
	background: #00000055
	top: 0
	bottom: 0

.loader
	position: fixed
	top: 50%
	left: 50%
	transform: translateX(-50%) translateY(-50%)
svg path,
svg rect
  fill: #FF6700
</style>
